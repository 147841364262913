

.overlay {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Halbtransparenter Hintergrund */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Über allen anderen Inhalten */
}