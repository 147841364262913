html {

    overflow-y: auto;
}


.fuerte {
   
    background-color: white;
    box-shadow: 0px 20px 20px 20px rgba(0, 0, 0, 0.3);
    
    

}

.bar {
    position: fixed;
    width: 100%;
    bottom: 0%;
    justify-content: center;
    z-index: 2;
}

.fuerte2 {

    position: fixed; 
    width: 90%;
    bottom: 13%;
    z-index: 3;
}

.fuerte2Mobile   {

    position: fixed; 
    width: 100%;
    bottom: 20vw;
    
    z-index: 3;

}

.selection {

 
    border-radius: 50%;
    width: 6vh;
    height: 6vh;
    background-color: #F1F1F1;
    color: #ed1e24;
    border: none;
    font-weight: bold;
    font-size: 20px;
}

.selection-anders {

 

    background-color:  #ed1e24;
    color: #F1F1F1;
    border-radius: 50%;

}



.selection:hover {

 
    background-color: #ed1e24;
    color: #F1F1F1;
   
}

.port {

    font-size: 20px;
    font-weight: bold;
}
.portMobile {

    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0%;
}

.selectionMobile {

    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #F1F1F1;
    color: #ed1e24;
    border: none;
    font-weight: bold;
}


.selectionMobile:hover {

 
    background-color: #ed1e24;
    color: #F1F1F1;
   
}

.input-range {

    width: 50vw;
}

.input-range-mobile {

    width: 80vw;
}

.information {

    position: fixed;
    top: 1%;
    right: 1%;
    color: #ed1e24;
}

.subtitle {

    font-weight: bold;
    color: "#ed1e24"
}

.buttons {

    opacity: 100%;
    
}

.rotate {
    border-radius: 50%;
    width: 6vh;
    height: 6vh;
    background-color: #F1F1F1;
    color: #ed1e24;
    border: none;
    font-weight: bold;
    font-size: 20px;

}

.scrollable-buttons {

    overflow-x: scroll;
    white-space: nowrap;
  }
  
  .scrollable-buttons::-webkit-scrollbar {
    display: none;
  }

  .create-mobile {

 
    border-radius: 50%;
    width: 50px;
    height: 50px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #F1F1F1;
    color: #ed1e24;
    border: none;
    
    
  }