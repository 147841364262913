

.time {

    font-size: 15px;
}


.mama {

    background-color: #ed1e24;
}
