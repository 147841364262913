body {
  display: flex;
  flex-direction: column;
}
.principal {
  display: flex;
  padding: 1%;
  height: 95%;
}

.btn {
  background-color: #ed1e24;
  color: #ed1e24;
  font-weight: bold;
}

.text {
  font-size: 17px;
  font-weight: bold;
  color: #ed1e24;
}

p {
  color: #ed1e24;
}

.hola2 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.position {

  position: fixed;
  bottom: 3%;
  right: 5%;
  flex: 2;
}

.create {

 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  align-items: center;
  background-color: #ed1e24;
  color: #F1F1F1;
  border: none;
  font-weight: bold;
  font-size: 25px;
}

.create-mobile {

 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #ed1e24;
  color: #F1F1F1;
  border: none;
  
  
}

.create:hover {

 
  background-color: #F1F1F1;
  color: #ed1e24;
 
}

.scrollable-buttons {

  overflow-x: scroll;
  white-space: nowrap;
}

.scrollable-buttons::-webkit-scrollbar {
  display: none;
}

