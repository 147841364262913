

.subtitle {

    color: #ed1e24;
    font-size: 20px;
    font-weight: 700;
}

.xDelete {
    border-radius: 25%;
    width: 25px;
    height: 25px;
    background-color: #ed1e24;
    color: #F1F1F1;
    border: none;
    font-weight: bold;
}
